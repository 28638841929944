@import "@design";

.crew-calendar {
  display: flex;
  flex: 0 0 70%;
  flex-direction: column;
  max-height: 100vh;
  margin: 0 auto;
  overflow: auto;

  @media screen {
    background-color: #9e9e9e;
  }

  @media print {
    .cal-cell,
    .cal-cell.weekend-header {
      border-color: black;
    }
  }
}

.header--wrap {
  position: sticky;
  top: 0;
  z-index: 5;
  @media print {
    position: relative;
  }
}

.calendar-inner {
  flex: 1 0 100%;
  // min-height: calc(100% - 120px);
  min-height: calc(100% - 55px);
}

.cal-body-inner {
  display: flex;
  flex-direction: column;
}

.cal-timeout-error {
  margin-top: 20px;
}

.reload-button {
  cursor: pointer;
}
