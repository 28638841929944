@import "@design";

// for parent component
.location-item {
  &.sortable-ghost {
    .hidden-while-dragging {
      display: none;
    }
  }
}

.location-data {
  flex: 0 0 100%;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
  line-height: 1.2;
  overflow: hidden;
  margin-bottom: 2px;
  &.sm-data {
    font-size: 12px;
  }
  &.permit-hours {
    font-weight: bold;
  }

  &.mini {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.is-for-print {
    overflow: visible;
    white-space: normal;
    text-overflow: initial;
    line-height: 1.1;
    font-size: 13px;
    &.sm-data {
      font-size: 11px;
    }
  }
}
