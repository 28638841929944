@import "@design";

.location-item {
  display: flex;
  flex: 1 0 280px;
  flex-wrap: wrap;
  align-self: flex-start;
  justify-content: center;
  max-width: calc(100% - 10px);
  padding: 10px;
  margin: 2.5px 5px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  // border-left: 4px solid transparent;
  // border-radius: 3px;

  &:hover {
    background-color: #e6e6e6;
  }

  &.is-on-cal {
    background: lighten(black, 90%);
    border: 2px solid lighten(black, 20%) !important;
    font-weight: bold;
    &:hover {
      background-color: lighten(black, 83%) !important;
    }
  }
  &.modal-open {
    color: black !important;
  }

  &.on-hold {
    .on-hold-icon {
      position: absolute;
      height: 35px !important;
      width: 35px !important;
      top: 2px;
      right: 2px;
    }
  }

  &.mini {
    .on-hold-icon {
      height: 24px !important;
      width: 24px !important;
    }
  }
}
