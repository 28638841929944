@media screen {
  .print-only {
    display: none !important;
    visibility: hidden;
  }
}

@media print {
  //
  // Global
  //
  #app {
    font-size: 12px;
    .view-content {
      padding-top: 0 !important;
    }
  }
  .no-print {
    display: none !important;
    visibility: hidden;
  }

  * {
    color: black !important;
    background: none !important;
    box-shadow: none !important;
  }

  .material-icons:not(.print-only) {
    display: none;
  }
  .print-only {
    .material-icons {
      display: inherit;
    }
  }

  .container {
    width: 100%;
  }

  //
  // Header
  //
  .nav-bar,
  header.meta--wrap {
    color: black;
  }
  .nav-bar {
    height: 2rem;
  }
  .daily-report-meta {
    padding: 0;
    font-size: 0.9rem;
    .meta-title {
      font-size: 1rem;
    }
  }
} // end @media print
