$gray-dark: #616161;
$gray-body: #757575;
$gray-mid: #b5b5b5;
$gray-light: #e8e8e8;

$red: #ec5757;
$light-red: lighten($red, 24);
$navbar-red: #e57373;
$orange: #ff9800;
$green: #4caf50;
$blue: #1497f7;
$teal: #87ffcd;
$purple: #756fdd;
