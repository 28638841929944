.report-revisions {
  margin:0;
  text-align:left;
  border-top:none;
  &:nth-child(odd) {
    .save-point-header {
      background-color:darken(white, 5%);
    }
  }
}
$margin: calc($gutter / 2);
.revision {
  .save-point-header {
    position:relative;
    padding:calc($gutter / 2);
    font-size:0.9rem;
    transition:background-color .3s ease;

    &:hover {
      background-color:darken(white, 8%)
    }

    .show-hide {
      position: absolute;
      top:$margin;
      right:$margin;
      line-height:$gutter;
    }
  }
  .author-name {
    margin-right:3px;
    font-weight:bold;
  }
  .table-name-header {
    padding:calc($gutter / 4) calc($gutter / 2);
    font-size:1.1rem;
    font-weight:bold;
    color:black;
    text-align:left;
    background:$gray-light;
  }
  .header {
    margin-bottom:$margin;
    margin-left:$margin*1.5;
    font-size:1.2rem;
    font-weight:bold;
  }
  .revision-mutation-title {
    margin-bottom:calc($margin / 2);
    margin-left:$margin;
    font-weight:bold;
    &.removed {
      font-style:italic;
      color:darken(white, 30%);
    }
  }
}
.revision-mutation {
  padding-bottom:calc($margin / 2);
  margin-bottom:$margin;
  margin-left:$margin*1.5;
  &:not(:last-child){
    border-bottom:1px solid darken(white, 20%);
  }
  &-field {
    margin-bottom:calc($margin / 2);
    margin-left:$margin*2;
  }
}
