@import "@design";

.locations-wrap {
  flex: 1 1 15%;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  background: lighten(black, 60%);
  transition: all 0.3s ease;
  .column-title {
    color: white;
  }
  &.mini {
    padding-top: 50px !important;
  }
}
.locations-title {
  align-self: center;
}
.locations {
  display: flex;
  flex-wrap: wrap;
}
.md-menu {
  width: 100%;
  margin-bottom: 5px;
}
.locations-list-wrap {
  flex-basis: 80%;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-x: hidden;
  &.delete-zone {
    background: lighten(red, 15);
  }
}
.drawer-close-button {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}
