body {
  line-height: 1.2;
  .display-1,
  .display-2,
  .display-3,
  .display-4,
  .display-5,
  .display-6 {
    font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
  }
}

a {
  text-decoration: none;
}
