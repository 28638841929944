// stylelint-disable-next-line
body,
#app.theme--light {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: white;
  background-color: $gray-body;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: white;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.view-content {
  @media (max-width: 600px) {
    padding-top: 56px;
  }
}
