$columns: 12;

.flex-container {
  display: flex;
  flex-wrap: wrap;

  &.flex-centered {
    justify-content: center;
  }

  @mixin flex-grid {
    $margin: 1.5%;
    &.flex-grid {
      justify-content: space-between;
    }

    .col {
      @media #{$mobile} {
        width: 100%;
      }
    }
  }

  @include flex-grid;

  .flex-item {
    flex: 1;
    flex-basis: 100%;

    &.flex-auto {
      flex-basis: auto;
    }

    @mixin flex-col-list {
      $breakpoints: (
        xs: $mobile,
        sm: $tablet,
        md: $gt_tablet,
        lg: $gt_1024,
        print: 'print',
      );

      @each $size, $bp in $breakpoints {
        @for $i from 1 through 12 - 1 {
          $flex: 0 0 #{calc(100% / $i)};
          &.flex-#{$i}-#{$size} {
            @media #{$bp} {
              flex: $flex;
            }

            @media screen and (max-width: 319px) {
              flex-basis: 100%;
            }
          }
        } // for i - 12
      } // each
    } // flex-col-list

    @include flex-col-list;

    @mixin flex-span-list {
      $breakpoints: (
        xs: $mobile,
        sm: $tablet,
        md: $gt_tablet,
        lg: $gt_1024,
        print: 'print',
      );

      @each $size, $bp in $breakpoints {
        @for $i from 1 through 12 {
          &.flex-#{$i}_12-#{$size} {
            @media #{$bp} {
              flex: 0 0 calc(#{calc($i / 12) * 100%});
            }
          }

          &.flex-offset-#{$i}-#{$size} {
            @media #{$bp} {
              margin-left: #{calc($i / 12) * 100%};
            }
          }
        } // for i - 12
      } // @each
    } // end mixin def

    @include flex-span-list;

    &.flex-shrink-print {
      @media print {
        flex-basis: auto !important;
        flex-grow: 0 !important;
        flex-shrink: 1 !important;
      }
    }
    &.flex-grow-print {
      @media print {
        flex-grow: 1 !important;
        flex-shrink: 0 !important;
      }
    }
  } // .flex-item
}
